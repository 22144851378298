import { Theme } from "@theme-ui/core";

import { Icon } from "@/components/Icon";

export const PremiumBadge: React.FC = () => (
  <Icon
    icon="premium"
    size={3}
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 3,
      width: 3,
      border: (theme: Theme) =>
        `2px solid ${theme.colors?.surface_light2_dark2}`, // 18px (icon size) - 16px (background size) = 2px (border)
      boxSizing: "content-box",
      backgroundColor: "primaryPurple",
      borderRadius: "50%",
      color: "primaryDarkPurple",
    }}
  />
);
