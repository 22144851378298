import { ThemeUIStyleObject } from "@theme-ui/css";

export const headerStyles: ThemeUIStyleObject = {
  // editor header styles - scoped to the block list layout
  ".block-editor-block-list__layout": {
    ".wp-block-heading": {
      my: 3,
    },

    "h1.wp-block-heading": {
      fontSize: "1.4rem",
      fontWeight: "heading",
      lineHeight: 2,
    },

    "h2.wp-block-heading": {
      fontSize: "1.2rem",
      fontWeight: "heading",
      lineHeight: "heading",
    },

    "h3.wp-block-heading": {
      fontSize: "1.1rem",
      lineHeight: "heading",
      color: "red",
    },

    "h4.wp-block-heading": {
      lineHeight: 3,
      textTransform: "uppercase",
      color: "editorTextSecondary",
      fontWeight: "body",
    },

    "h5.wp-block-heading": {
      fontSize: "0.9rem",
      fontWeight: "body",
      lineHeight: 3,
      textTransform: "uppercase",
    },

    "h6.wp-block-heading": {
      fontSize: "0.8rem",
      lineHeight: 3,
      textAlign: "left",
      width: "fit-content",
      textTransform: "uppercase",
      backgroundColor: "editorBgHighlight",
      paddingX: "6px",
      fontWeight: "body",
      borderRadius: 2,
      border: "1px solid",
      borderColor: "editorBorderHighlight",
    },
  },
};
