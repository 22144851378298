import { RTJNode, TextNode } from "@/../types/rtj-format";

export const convertHTMLToRTJ = (htmlString: string) => {
  const content = new DOMParser()
    .parseFromString(htmlString, "text/html")
    .querySelector("body");

  const childNodes = content?.childNodes;

  if (childNodes?.length) {
    return convertDomNodesToRTJNodes(childNodes);
  }

  return [{ text: "" }];
};

const convertDomNodesToRTJNodes = (nodes: NodeList) => {
  return Array.from(nodes).reduce<TextNode[]>((acc, node) => {
    // DOM nodes can turn into an array of RTJ nodes, it's not necessarily 1:1
    const resultArray = convertNodeToRTJFormatting(node).filter((result) => {
      if (result.text.includes("\n") && result.text.trim().length < 1) {
        // its just newlines, so attach it to the last piece of content
        const lastItem = acc[acc.length - 1];
        if (lastItem) {
          acc = [
            ...acc.slice(0, acc.length - 1),
            { ...lastItem, text: (lastItem.text += result.text) },
          ];
          return false;
        }
        return true;
      }
      return result.text !== "";
    });
    return [...acc, ...resultArray];
  }, []);
};

export const rgbaToHex = (rgba: string) => {
  if (!rgba.startsWith("rgba")) {
    return rgba.replace("#", "");
  }
  const matches = rgba.match(/[0-9|.]+/g);

  if (!matches) {
    return "";
  } else {
    return matches
      .map((x, i) => {
        const parsed =
          i === 3
            ? parseInt((255 * parseFloat(x)).toString(), 10).toString(16)
            : parseInt(x).toString(16);

        return parsed.length === 1 ? `0${parsed}` : parsed;
      })
      .join("");
  }
};

export const hexToRGBA = (hexCode: string, opacity = 1) => {
  let hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity})`;
};

export const convertNodeToRTJFormatting: (
  node: Node,
  textNode?: TextNode,
) => TextNode[] = (
  node: Node,
  textNode: TextNode = { attributes: {}, text: "" },
) => {
  switch (node.nodeName) {
    case "A":
      // If the node text is the same as the link then we should set the autolink attribute,
      // If not then we should set the linkURL attribute
      // We strip off any trailing backslash to do the comparision.
      if (
        node.textContent?.replace(/\/$/, "") ===
        (node as HTMLAnchorElement).href.replace(/\/$/, "")
      ) {
        textNode.attributes = {
          ...textNode.attributes,
          autolink: true,
        };
      } else {
        textNode.attributes = {
          ...textNode.attributes,
          linkURL: (node as HTMLAnchorElement).href,
        };
      }
      break;
    case "BR":
      textNode.text = "\n";
      break;
    case "EM":
      textNode.attributes = {
        ...textNode.attributes,
        italic: true,
      };
      break;
    case "S":
      textNode.attributes = {
        ...textNode.attributes,
        strikethrough: true,
      };
      break;
    case "STRONG":
      textNode.attributes = {
        ...textNode.attributes,
        bold: true,
      };
      break;
    case "MARK": {
      const bgColor = (node as HTMLElement).style.getPropertyValue(
        "background-color",
      );
      textNode.attributes = {
        ...textNode.attributes,
        highlightedColor: rgbaToHex(bgColor),
      };
      break;
    }
    case "CODE": {
      textNode.attributes = {
        ...textNode.attributes,
        inlineCode: true,
      };
      break;
    }
    case "U":
      textNode.attributes = {
        ...textNode.attributes,
        underline: true,
      };
      break;
    case "#text":
      textNode.text = generateNodeText(node as HTMLElement);
  }

  // basic text content can contain several child nodes in cases such as nested formatting.
  // we need to flatten these to an array of consecutive nodes
  return Array.from(node?.childNodes).reduce<TextNode[]>(
    (acc, node) => {
      const resultArray = convertNodeToRTJFormatting(node).filter((result) => {
        // add any attributes present on the parent node to the children
        result.attributes = {
          ...textNode.attributes,
          ...result.attributes,
        };
        // filter out empty nodes
        return result.text !== "";
      });
      return [...acc, ...resultArray];
    },
    [textNode],
  );
};

const generateNodeText = (node: HTMLElement) => {
  return node.textContent || "";
};

export const removeZeroWidthNonBreakingSpaces = (contents: RTJNode[]) =>
  contents.reduce((acc, node) => {
    if (!("embeddedObjects" in node)) {
      const newText = node.text.replace(/\ufeff/g, "");
      return [...acc, { ...node, text: newText }];
    }
    return [...acc, node];
  }, [] as RTJNode[]);
