import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { ItemGroup } from "@/components/ItemGroup";
import { EditLinkButton } from "@/components/Settings/EditLinkButton";
import { ToggleSetting } from "@/components/Settings/ToggleSetting";
import { ServerFlagDBRow } from "@/data/db/migrations/server_flags";
import { isFlagEnabled } from "@/data/utils/serverFlags";

type Props = {
  flag: ServerFlagDBRow;
  disabled: boolean;
  onToggle: (flagId: string, value: boolean) => void;
};

export const GoDeeper: React.FC<Props> = observer(
  ({ flag, disabled, onToggle }) => {
    const { __ } = useI18n();

    const value = isFlagEnabled(flag);

    return (
      <>
        <ItemGroup addMargin={true}>
          <ToggleSetting
            label={__("AI Go Deeper")}
            checked={value}
            onChange={() => onToggle(flag.id, !value)}
            disabled={disabled}
          />
        </ItemGroup>
        <p>
          {__(
            `Generate personalized prompts in the editor based on what you’ve written to spark fresh ideas and deeper reflections. `,
          )}
          <EditLinkButton
            onClick={() => {
              analytics.tracks.recordEvent(EVENT.buttonTap, {
                button_identifier: "go_deeper_learn_more_clicked",
              });
              window.open(
                "https://dayoneapp.com/labs/ai-features",
                "_blank",
                "noreferrer,noopener",
              );
            }}
            label={__("Learn more.")}
            title={__("Learn more.")}
          />
        </p>
      </>
    );
  },
);
