import { d1Classes } from "@/D1Classes";
import { md5 } from "@/crypto/utils/md5";
import { UserModel } from "@/data/models/UserModel";
import { ClientMeta } from "@/data/repositories/V2API";

export const DEVICE_NAME =
  typeof window !== "undefined" && window.IS_ELECTRON_APP
    ? "DesktopApp"
    : "WebApp";
let user: UserModel | null;

const generateDeviceFingerprint = async (clientMeta: ClientMeta) => {
  if (!user) {
    user = await d1Classes.userStore.getActiveUser();
  }
  const osName = clientMeta.creationOSName;
  const browserName = clientMeta.browserName;
  const language = navigator.language;

  const fingerprint = [osName, browserName, language];
  if (user?.id) {
    fingerprint.push(user.id);
  }
  return md5(fingerprint.join("::"));
};

export const getClientMeta = async () => {
  const clientMeta: ClientMeta = {
    deviceId: DEVICE_NAME,
    deviceName: DEVICE_NAME,
    creationDeviceModel: "Unknown Device",
    creationDevice: "Unknown Device",
    creationDeviceType: DEVICE_NAME,
    creationOSName: "Unknown OS",
    creationOSVersion: "Unknown OS Version",
    browserVersion: "",
    browserName: "",
  };

  // userAgentData is still experimental and not supported in all browsers
  // We'll use it when we can and then fall back to parsing user agent when we can't
  // @ts-ignore
  if (navigator.userAgentData) {
    // @ts-ignore
    const ua = await navigator.userAgentData.getHighEntropyValues([
      "platformVersion",
    ]);
    clientMeta.creationOSName = ua.platform;
    clientMeta.creationOSVersion = ua.platformVersion;
    clientMeta.creationDevice = ua.mobile ? "Mobile" : "Desktop";
    const knownBrowsers = [
      "Google Chrome",
      "Brave",
      "Microsoft Edge",
      "Opera",
      "Chromium",
    ];
    for (const known of knownBrowsers) {
      const brand = ua.brands.filter(
        (b: { brand: string; version: string }) => b["brand"] === known,
      );
      if (brand.length > 0) {
        clientMeta.creationDeviceModel = `${known} ${brand[0].version}`;
        clientMeta.browserVersion = brand[0].version;
        clientMeta.browserName = known;
        break;
      }
    }
  } else {
    const ua = navigator.userAgent;
    let version;
    const browser = ua.match(
      /(opera|chrome|safari|firefox|msie|trident|crios)\/?\s*(\.?\d+(\.\d+)*)/i,
    );
    if (
      browser &&
      (version = navigator.userAgent.match(/version\/([.\d]+)/i)) !== null
    ) {
      browser[2] = version[1];
    }
    if (browser) {
      const browserVersion = browser[2].split(".")[0];
      clientMeta.creationDeviceModel = `${browser[1]} ${browserVersion}`;
      clientMeta.browserName = browser[1];
      clientMeta.browserVersion = browserVersion;
    }

    // I'm not sure if this is the best way to detect iPad, but this works
    const isIpad =
      /Macintosh/i.test(ua) &&
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 1;

    clientMeta.creationDevice =
      isIpad || /tablet/i.test(ua)
        ? "Tablet"
        : /mobile/i.test(ua)
          ? "Mobile"
          : "Desktop";

    if (navigator.platform) {
      clientMeta.creationOSName = isIpad ? "iPad" : navigator.platform;
    }
  }

  const deviceId = await generateDeviceFingerprint(clientMeta);
  clientMeta.deviceId = deviceId;
  clientMeta.deviceName =
    typeof window !== "undefined" && window.IS_ELECTRON_APP
      ? `${DEVICE_NAME} ${clientMeta.creationOSName}`
      : `${DEVICE_NAME} ${clientMeta.browserName} ${clientMeta.browserVersion}`;

  return clientMeta;
};
