import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import { d1Classes } from "@/D1Classes";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { Icon } from "@/components/Icon";
import { ItemGroup } from "@/components/ItemGroup";
import { EditLinkButton } from "@/components/Settings/EditLinkButton";
import { AIFeatures } from "@/components/Settings/Labs/AIFeatures";
import { GoDeeper } from "@/components/Settings/Labs/GoDeeper";
import { NoLabs } from "@/components/Settings/Labs/NoLabs";
import { SettingsPanel } from "@/components/Settings/SettingsPanel";
import { isFlagEnabled } from "@/data/utils/serverFlags";
import {
  featureFlagsViewState,
  snackbarViewState,
} from "@/view_state/ViewStates";

export const Labs: React.FC = observer(() => {
  const { __ } = useI18n();
  const labServerFlags = featureFlagsViewState.labServerFlags;

  if (labServerFlags.length === 0) {
    return <NoLabs />;
  }

  const goDeeper = labServerFlags.find((feature) => feature.id === "go_deeper");
  const aiFeatures = labServerFlags.find(
    (feature) => feature.id === "ai-features",
  );

  const isAIEnabled = isFlagEnabled(aiFeatures);

  const toggleFlag = async (flagId: string, value: boolean) => {
    const updated = await d1Classes.userStore.updateServerFeatureFlag(
      flagId,
      value,
    );
    if (!updated) {
      snackbarViewState.newMessage(__("Setting failed to update"));
    }
    if (
      updated &&
      flagId === "ai-features" &&
      !value &&
      goDeeper?.user_value === true
    ) {
      d1Classes.userStore.updateServerFeatureFlag("go_deeper", false);
    }
  };

  return (
    <SettingsPanel
      header={
        <span
          sx={{
            "&&": { display: "inline-flex", alignItems: "normal", gap: 2 },
          }}
        >
          <Icon icon="experiments" size={3} />
          <div>{__("Labs")}</div>
        </span>
      }
      sx={{
        "&& p": {
          fontSize: 0,
          color: "textSecondary",
          mt: 1,
          p: 2,
        },
        "&& button": {
          fontSize: 0,
        },
      }}
    >
      {aiFeatures && <AIFeatures flag={aiFeatures} onToggle={toggleFlag} />}
      {goDeeper && (
        <GoDeeper
          flag={goDeeper}
          disabled={!isAIEnabled}
          onToggle={toggleFlag}
        />
      )}{" "}
      <ItemGroup addMargin={true}>
        <div
          sx={{
            mt: 1,
            py: 2,
            px: 3,
            "&& button": { lineHeight: 1.6, fontSize: 1 },
          }}
        >
          <EditLinkButton
            onClick={() => {
              analytics.tracks.recordEvent(EVENT.buttonTap, {
                button_identifier: "labs_shared_feedback_clicked",
              });
              window.open(
                "https://dayoneapp.com/labs/feedback/",
                "_blank",
                "noreferrer,noopener",
              );
            }}
            label={__("Share Feedback")}
            title={__("Share Feedback")}
          />
        </div>
      </ItemGroup>
    </SettingsPanel>
  );
});
