import { ServerFlagDBRow } from "@/data/db/migrations/server_flags";

export const isFlagEnabled = (flag?: ServerFlagDBRow) => {
  if (!flag) {
    return false;
  }

  if (flag.user_value != null) {
    return flag.user_value;
  }

  return flag.default_value;
};
