import { AIAPICaller } from "@/data/api_callers/AIAPICaller";

export class AIController {
  constructor(private aiAPICaller: AIAPICaller) {}

  async fetchGoDeeperPrompts(entryText: string) {
    return await this.aiAPICaller.fetchGoDeeperPrompts(entryText);
  }

  async fetchGoDeeperInitialPrompts() {
    return await this.aiAPICaller.fetchGoDeeperInitialPrompts();
  }
}
