import { useI18n } from "@wordpress/react-i18n";

import { Icon } from "@/components/Icon";
import { SettingsPanel } from "@/components/Settings/SettingsPanel";

export const NoLabs = () => {
  const { __ } = useI18n();
  return (
    <SettingsPanel
      header={
        <span
          sx={{
            "&&": { display: "inline-flex", alignItems: "normal", gap: 2 },
          }}
        >
          <Icon icon="experiments" size={3} />
          <div>{__("Day One Labs")}</div>
        </span>
      }
    >
      <div
        sx={{
          mt: 4,
          px: 3,
          color: "textSecondary",
        }}
      >
        <div
          sx={{
            backgroundColor: "surface_light2_dark2",
            p: 3,
            borderRadius: 2,
            mb: 3,
          }}
        >
          {__("No Labs available at this time")}
        </div>
        <p sx={{ mb: 3 }}>
          {__(
            "Get early access to experimental features and help shape the future of journaling.",
          )}
        </p>
        <p sx={{ mb: 3 }}>
          {__(
            "Explore new tools, share your feedback, and be part of what's next for Day One.",
          )}
        </p>
        <p>
          {__(
            "When a labs feature is available to test, a toggle will appear for it on this screen. By opting in, you'll get to explore beta features that are still in development. Things might not always work perfectly and labs features may be removed at any time, but your feedback helps us improve and prepare for a full release. Thanks for your patience!",
          )}
        </p>
      </div>
    </SettingsPanel>
  );
};
