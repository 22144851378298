import { ThemeUIStyleObject } from "@theme-ui/css";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import { useColorMode } from "@/styles/DayOneColorModeProvider";
import { highlightMarkedTerms } from "@/utils/strings";
const styles: ThemeUIStyleObject = {
  flex: 1,

  // these styles make text truncation work properly
  overflow: "hidden",
  "& p, & h1, & h3, & h4, & h5, & h6, & li, & pre, & code": {
    overflowX: "clip",
  },

  "& p, & li": {
    color: "editorTextSecondary",
  },

  /* properly display bullets on lists without breaking truncation */
  "& li::marker": {
    // don't add a line break between the bullet and the list item if the list item is long
    whiteSpace: "nowrap",
  },
  // we have to move the bullets to the inside so they don't get clipped with overflow-x
  "& ul": {
    listStyleType: "none",
    listStylePosition: "inside",
  },
  "& ol": {
    listStyleType: "none",
  },

  /* checkboxes */
  "& li.task-list-item": {
    // these styles are from checklist.ts
    display: "flex",
    gap: 1,
    color: "editorTextDefault",
    listStyleType: "none",
    // checkboxes need different padding because they insert a checkbox in ::before
    "::before": {
      display: "none",
    },
    "input[type=checkbox]": {
      marginLeft: "0 !important",
      display: "none",
    },
  },
  "& li.task-list-item input:checked + span": {
    color: "editorTextDisabled",
    textDecoration: "line-through",
  },

  // these styles give the preview a consistent height
  lineHeight: "preview",
  fontSize: 1,
  fontWeight: "body",

  height: (theme) =>
    `calc(${
      theme.lineHeights?.["preview" as keyof typeof theme.lineHeights] || 1.4
    }em   * 3)`,

  // blockquote styles
  "& blockquote": {
    fontSize: 1,
    color: "editorTextSecondary",
  },

  /* font size and line height adjustments of specific elements to prevent over/underflow */
  "& h1, & h2, & h3, & h4, & h5, & h6": {
    fontSize: 1, // size these down to the default font size
    fontWeight: "heading",
  },
  "& h3": { color: "red" },
  "& h4": {
    textTransform: "uppercase",
    color: "editorTextSecondary",
    fontWeight: "body",
  },
  "& h5": {
    textTransform: "uppercase",
    fontWeight: "body",
  },
  "& h6": {
    textTransform: "uppercase",
    fontWeight: "body",
    color: "editorTextSecondary",
  },

  // Normalize bold and italic text
  "& strong, & em": {
    fontWeight: "body",
    fontStyle: "normal",
  },
};

export const MarkdownPreview: React.FC<{
  entryText: string;
  invertedText?: boolean;
  withHighlights?: boolean;
}> = observer(({ entryText, invertedText, withHighlights }) => {
  const additionalStyles: ThemeUIStyleObject = invertedText
    ? {
        "& li.task-list-item": {
          // these styles are from checklist.ts
          color: "textPrimaryInverse",
          listStyleType: "none",
          display: "flex",
          gap: 1,
          // checkboxes need different padding because they insert a checkbox in ::before
          "::before": {
            display: "none",
          },
          input: { display: "none" },
        },
        "& li.task-list-item input:checked + span": {
          color: "textSecondaryInverse",
          textDecoration: "line-through",
        },
        "& h4, & h5": {
          color: "textPrimaryInverse",
        },
        "& h6": {
          textTransform: "uppercase",
          fontWeight: "body",
          color: "textSecondaryInverse",
        },
        "& h3": { color: "redLighter" },
        "& p, & li": {
          color: "textPrimaryInverse",
        },
      }
    : {};

  const { _x } = useI18n();
  const { inDarkMode } = useColorMode();

  const ariaLabelPreviewWordMax = 20;

  const previewText = entryText
    .replaceAll(/<[^>]*>/g, "")
    .split(" ")
    .slice(0, ariaLabelPreviewWordMax)
    .join(" ")
    .replace(/['\n]+$/, "");

  const backgroundColor = inDarkMode ? "#cccc00; " : "yellow";

  const preview = withHighlights
    ? highlightMarkedTerms(entryText, backgroundColor)
    : entryText;

  // Use regex to see if the preview has a heading and text after it
  const matchHeadingText = preview.match(
    /^<(h1|h2|h3|h4|h5|h6)[^>]*>.*<\/\1>(.*)/,
  );
  const shouldTruncate =
    matchHeadingText?.[1] && matchHeadingText?.[2]?.length > 0;

  return (
    <div
      aria-label={
        !previewText
          ? _x(
              "which is empty.",
              "this is for screen readers to know that the entry is empty",
            )
          : `[${_x(
              "entry preview",
              "this is for screen readers before the entry preview text",
            )}]: ${previewText}, [${_x(
              "end of entry preview",
              "this is for screen readers after the entry preview text",
            )}].
        `
      }
      sx={{ ...styles, ...additionalStyles }}
    >
      <span
        className="entry-preview"
        dangerouslySetInnerHTML={{ __html: preview }}
        sx={{
          overflowWrap: "break-word",
          ...(shouldTruncate && {
            "& :first-of-type": {
              width: "auto",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            },
          }),
        }}
      />
    </div>
  );
});
