import {
  BlockInstance,
  createBlock,
  findTransform,
  getBlockAttributes,
  getBlockTransforms,
  getBlockTypes,
} from "@wordpress/blocks";
import showdown from "showdown";

export interface Options {
  enableAutoHeader?: boolean;
}

import {
  AUDIO_BLOCK_ID,
  IMAGE_BLOCK_ID,
  VIDEO_BLOCK_ID,
} from "@/components/Editor/blocks/constants";
import { convertGBBlocksToRTJNodes } from "@/components/Editor/gb2rtj/gb2rtj";
import { registerBlocks } from "@/components/Editor/utils/register-blocks";
// See https://github.com/WordPress/gutenberg/tree/trunk/packages/blocks/src/api/raw-handling
// specifically https://github.com/WordPress/gutenberg/blob/trunk/packages/blocks/src/api/raw-handling/html-to-blocks.js
export const htmlToBlocks = (
  html: string,
  journalId: string,
  entryId: string,
  { enableAutoHeader }: Options = { enableAutoHeader: true },
): BlockInstance[] => {
  if (getBlockTypes().length === 0) {
    registerBlocks();
  }
  const doc = document.implementation.createHTMLDocument("");
  doc.body.innerHTML = html;
  return Array.from(doc.body.children).flatMap((node, index) => {
    const nodeHTML: string = node.innerHTML;
    // Manually detect photo moment and create block
    if (nodeHTML.startsWith('<img src="dayone-moment://')) {
      const id = nodeHTML
        .replace('<img src="dayone-moment://', "")
        .split('"')[0];
      return createBlock(IMAGE_BLOCK_ID, { clientId: id, journalId, entryId });
    }

    // Auto-header logic. If the first block is a paragraph and its content
    // length is less than or equal to MAX_AUTO_HEADER_LENGTH, then we change the
    // block type to an h1.
    const MAX_AUTO_HEADER_LENGTH = 100;
    if (
      enableAutoHeader &&
      index === 0 &&
      node.tagName === "P" &&
      (node.textContent?.length ?? 0) <= MAX_AUTO_HEADER_LENGTH
    ) {
      return createBlock("core/heading", {
        content: node.textContent,
        level: 1,
      });
    }

    switch (node.tagName) {
      case "VIDEO": {
        const id = nodeHTML
          .replace('<source src="dayone-moment:/video/', "")
          .split('"')[0];
        return createBlock(VIDEO_BLOCK_ID, {
          clientId: id,
          journalId,
          entryId,
        });
      }
      case "AUDIO": {
        const id = nodeHTML
          .replace('<source src="dayone-moment:/audio/', "")
          .split('"')[0];
        return createBlock(AUDIO_BLOCK_ID, {
          clientId: id,
          journalId,
          entryId,
        });
      }
      default:
        break;
    }

    fixBlockQuotes(node);

    const rawTransform = findTransform(getRawTransforms(), ({ isMatch }) => {
      if (!node || !isMatch) return false;
      return isMatch(node);
    });

    if (!rawTransform) {
      return createBlock(
        "core/html",
        getBlockAttributes("core/html", node.outerHTML),
      );
    }

    //@ts-ignore
    const { transform, blockName } = rawTransform;

    if (transform) {
      // @ts-ignore @todo not sure if this type can be fixed
      const result = transform(node, (stuff: { HTML: string }) => {
        return htmlToBlocks(
          stuff.HTML,
          journalId,
          entryId,
          // Make sure we pass "false" to disable auto-header logic
          // for nested blocks.
          { enableAutoHeader: false },
        );
      });
      return result || [];
    }

    return createBlock(
      blockName,
      getBlockAttributes(blockName, node.outerHTML),
    );
  });
};

const getRawTransforms = () => {
  return getBlockTransforms("from")
    .filter((t) => t.type === "raw")
    .map((transform) => {
      //@ts-ignore
      return transform.isMatch
        ? transform
        : {
            ...transform,
            //@ts-ignore
            isMatch: (node) =>
              //@ts-ignore
              transform.selector && node.matches(transform.selector),
          };
    });
};

// Showdown extension to process Day One Video Moments
// Regex adapted from https://github.com/showdownjs/showdown/blob/1165736eef2129e6345972cbc0eab5ff8f8c144d/src/subParsers/makehtml/images.js#L9
showdown.extension("d1-video-moment", function () {
  return [
    {
      type: "lang",
      filter: function (text) {
        const mainRegex = new RegExp(
          /!\[([^\]]*?)][ \t]*()\(dayone-moment:\/video\/[ \t]?<?([\S]+?(?:\([\S]*?\)[\S]*?)?)>?(?: =([*\d]+[A-Za-z%]{0,4})x([*\d]+[A-Za-z%]{0,4}))?[ \t]*(?:(["'])([^"]*?)\6)?[ \t]?\)/g,
        );
        text = text.replace(mainRegex, function (match) {
          const src = match.replace("![](", "").slice(0, -1);
          return `\n<video controls><source src="${src}" /></video>\n`;
        });

        return text;
      },
    },
  ];
});

showdown.extension("d1-audio-moment", function () {
  return [
    {
      type: "lang",
      filter: function (text) {
        const mainRegex = new RegExp(
          /!\[([^\]]*?)][ \t]*()\(dayone-moment:\/audio\/[ \t]?<?([\S]+?(?:\([\S]*?\)[\S]*?)?)>?(?: =([*\d]+[A-Za-z%]{0,4})x([*\d]+[A-Za-z%]{0,4}))?[ \t]*(?:(["'])([^"]*?)\6)?[ \t]?\)/g,
        );
        text = text.replace(mainRegex, function (match) {
          const src = match.replace("![](", "").slice(0, -1);
          return `\n<audio controls><source src="${src}" /></audio>\n`;
        });

        return text;
      },
    },
  ];
});

// See https://github.com/WordPress/gutenberg/tree/trunk/packages/blocks/src/api/raw-handling
// specifically https://github.com/WordPress/gutenberg/blob/trunk/packages/blocks/src/api/raw-handling/markdown-converter.js
const converter = new showdown.Converter({
  noHeaderId: true,
  tables: true,
  literalMidWordUnderscores: true,
  omitExtraWLInCodeBlocks: true,
  requireSpaceBeforeHeadingText: true,
  simpleLineBreaks: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  underline: true,
  tasklists: true,
  extensions: ["d1-video-moment", "d1-audio-moment"],
});

// Corrects the Slack Markdown variant of the code block.
// If uncorrected, it will be converted to inline code.
// https://get.slack.help/hc/en-us/articles/202288908-how-can-i-add-formatting-to-my-messages-#code-blocks

const slackMarkdownVariantCorrector = (text: string) => {
  return text.replace(
    /((?:^|\n)```)([^\n`]+)(```(?:$|\n))/,
    (match, p1, p2, p3) => `${p1}\n${p2}\n${p3}`,
  );
};

// A recursive function that makes sure every moment in the markdown is preceded by a double newline.
// If there's just one newline the HTML parser will treat the moment as an inline image embed and
// not a block.
export const ensureDoubleNewlineBeforeMoment = (text: string): string => {
  const foundIndex = text.indexOf("![](dayone-moment://");
  // If we have no more moments, return the text.
  if (foundIndex === -1) return text;
  // If the moment is the first thing in the text, just recurse to see if there are more moments that need fixing.
  if (foundIndex === 0)
    return text.slice(0, 1) + ensureDoubleNewlineBeforeMoment(text.slice(1));
  // If there's just one character before the moment and it's a newline,
  // stick another on the front
  if (foundIndex === 1 && text.at(0) == "\n")
    return (
      `\n${text.slice(0, 2)}` + ensureDoubleNewlineBeforeMoment(text.slice(2))
    );
  // Otherwise, we've got an image somewhere deeper in the text, so we need to
  // check the two characters preceding it to make sure they are newlines also.
  const charactersBefore = text.slice(foundIndex - 2, foundIndex);
  // If we're already preceded by two newlines, we're good to go.
  if (charactersBefore == "\n\n")
    return (
      text.slice(0, foundIndex + 1) +
      ensureDoubleNewlineBeforeMoment(text.slice(foundIndex + 1))
    );
  // If we've made it here, we've got a moment that has just one newline before it
  // in the middle of the string, and we're going to have to insert another one.
  const newBefore =
    text.slice(0, foundIndex) +
    // Add a newline
    "\n" +
    // We then add one character of the moment (the !)
    // to the current string, and recurse *without* that character
    // so that the next iteration doesn't start with a match.
    text.slice(foundIndex, foundIndex + 1);
  const newAfter = ensureDoubleNewlineBeforeMoment(text.slice(foundIndex + 1));
  return newBefore + newAfter;
};

const breakParagraphsWithLineBreaksIntoSeparateParagraphs = (html: string) => {
  return html.replace(/<p>([\s\S]+?)<\/p>/g, (match) => {
    return match.replace(/<br \/>\n/g, "</p>\n<p>");
  });
};

// There are some checks we want to do to correct problems that
// might be in the markdown before we convert.
const preprocessMarkdown = (text: string) => {
  let t = text;
  t = slackMarkdownVariantCorrector(t);
  t = ensureDoubleNewlineBeforeMoment(t);
  return t;
};

const postprocessMarkdown = (html: string) => {
  let h = html;
  h = breakParagraphsWithLineBreaksIntoSeparateParagraphs(h);
  return h;
};

export const markdownToHTML = (markdown: string) => {
  const html = converter.makeHtml(preprocessMarkdown(markdown));
  return postprocessMarkdown(html);
};

export const markdownToBlocks = (
  markdown: string,
  journalId: string,
  entryId: string,
) => {
  const html = markdownToHTML(markdown);
  return htmlToBlocks(html, journalId, entryId);
};

export const markdownToRTJ = (
  markdown: string,
  journalId: string,
  entryId: string,
) => {
  const blocks = markdownToBlocks(markdown, journalId, entryId);
  return convertGBBlocksToRTJNodes(blocks);
};

const fixBlockQuotes = (node: Node) => {
  if (node.nodeName === "BLOCKQUOTE") {
    const nodeTransformations = [];
    for (const child of node.childNodes) {
      /*  the markdown converter will add extra newlines outside of the pargraph
       like this:

        <blockquote>
          \n
          <p>foo</p>
          <br />
          <p>boo</p>
          \n
        </blockquote>

       which Gutenberg's checker will fail on when it's trying to check if
       it ONLY contains paragraphs. So we need to remove the extra newlines.
      */
      if (child.nodeName === "#text" && child.textContent?.trim() === "") {
        nodeTransformations.push(() => {
          // we delay the execution of removing the node
          // because it messes up the iteration. Meaning the loop
          // will skip the next node. This is because Node.childNodes
          // is a "live" collection.
          // ref: https://developer.mozilla.org/en-US/docs/Web/API/Node/childNodes
          node.removeChild(child);
        });
      } else {
        for (const grandchild of child.childNodes) {
          if (grandchild.nodeName === "#text") {
            /*
            Ok this is really weird so let me explain

            If you have markdown that looks like this:

            > This is one line
            Following line

            The markdown converter will convert it to this:
            <blockquote>
            <p>
              This is one line <br />
              \n Following line
            </p>

            This causes the "Following line" to be indented
            in the quote block like so:

            | This is one line
            |   Following line


            which doesn't match how Mac behaves which is like this:
            | This is one line
            | Following line
            */
            grandchild.textContent = grandchild.textContent?.trim() || "";
          }
        }
      }
    }
    nodeTransformations.forEach((t) => t());
  }
};
