import { Sentry } from "@/Sentry";
import { FetchWrapper } from "@/api/FetchWrapper";
import { goDeeperPromptsSchema } from "@/data/api_callers/schemas/AIAPISchemas";
import { safelyDecodeAPIResponse } from "@/data/api_callers/utils/safelyDecodeAPIResponse";

export type GoDeeperResponse = string[];
export class AIAPICaller {
  constructor(
    private fetchWrapper: FetchWrapper,
    private sentry: Sentry,
  ) {}

  async fetchGoDeeperPrompts(entryText: string): Promise<string[]> {
    const res = await this.fetchWrapper.fetchAPI(
      "/labs/ai/go-deeper",
      {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        body: JSON.stringify({ content: entryText }),
      },
      { expectedStatusCodes: [200, 401] },
    );

    if (res.status !== 200) {
      this.sentry.captureException(
        new Error(
          `Failed to fetch AI prompts: ${res.status} - ${res.statusText}`,
        ),
      );
      throw new Error("Failed to fetch AI prompts");
    }

    const prompts: GoDeeperResponse = await res.json();

    return safelyDecodeAPIResponse(goDeeperPromptsSchema, prompts);
  }

  async fetchGoDeeperInitialPrompts(): Promise<string[]> {
    const res = await this.fetchWrapper.fetchAPI(
      "/labs/ai/go-deeper/starters",
      {
        headers: {
          "Content-Type": "text/plain",
        },
      },
      { expectedStatusCodes: [200, 401] },
    );

    if (res.status !== 200) {
      this.sentry.captureException(
        new Error(
          `Failed to fetch AI prompts: ${res.status} - ${res.statusText}`,
        ),
      );
      throw new Error("Failed to fetch AI prompts");
    }

    const prompts: GoDeeperResponse = await res.json();

    return safelyDecodeAPIResponse(goDeeperPromptsSchema, prompts);
  }
}
