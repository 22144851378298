import { useBlockProps } from "@wordpress/block-editor";
import { Disabled } from "@wordpress/components";
import { CSSProperties } from "react";
import { useState } from "react";

import { FailedMedia } from "@/components/Editor/components/FailedMedia";
import { LoadingMediaPlaceholder } from "@/components/Editor/components/LoadingMediaPlaceholder";
import { UploadingMediaIcon } from "@/components/Editor/components/UploadingMediaIcon";
import { TopRightButtonWrapper } from "@/components/SyncableImage/TopRightButton";
import { useMoment } from "@/data/hooks/moments";
import { dayOneBlue } from "@/styles/theme";
import { getMediaWidth } from "@/utils/gallery";

type BlobVideoProps = {
  journalId: string;
  entryId: string;
  clientId: string;
  aspectRatio?: number;
  isSelected: boolean;
  heightInGallery?: number;
  blockClientId: string;
};

// Display a syncable video by MD5
export const SyncableVideo: React.FC<BlobVideoProps> = ({
  journalId,
  entryId,
  clientId,
  aspectRatio,
  isSelected,
  heightInGallery,
  blockClientId,
}) => {
  const { blob, isLoading, moment } = useMoment(journalId, entryId, clientId);
  const blockProps = useBlockProps({
    style: {
      position: "relative",
      border: "none",
    } as CSSProperties,
  });
  const [isHovered, setIsHovered] = useState(false);
  const isInGallery = !!(aspectRatio && heightInGallery);

  if (isLoading) {
    if (isInGallery) {
      return (
        <LoadingMediaPlaceholder
          styles={{
            flexBasis: 0,
            flexGrow: aspectRatio,
            aspectRatio,
          }}
        />
      );
    }

    if (moment) {
      return (
        <LoadingMediaPlaceholder
          key={moment.id}
          height={moment.height || undefined}
          width={moment.width || undefined}
        />
      );
    }

    return <LoadingMediaPlaceholder />;
  }

  if (blob && moment) {
    return (
      <div
        {...blockProps}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        data-momentid={moment?.id}
        sx={{
          position: "relative",
          boxShadow: isInGallery
            ? `0 0 0 1px ${isSelected ? dayOneBlue : "transparent"}`
            : undefined,
          "& video": {
            p: "1px",
            borderRadius: isInGallery ? undefined : "3px",
            border: isInGallery ? undefined : "3px solid",
            borderColor: isSelected ? dayOneBlue : "transparent",
            objectFit: "cover",
            height: isInGallery ? `${heightInGallery}px` : "100%",
            width: isInGallery
              ? `${getMediaWidth(aspectRatio, heightInGallery)}px`
              : undefined,
          },
          // This makes sure the "Disabled" component shows our video fully
          " > div": {
            display: "flex",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          },
        }}
      >
        <Disabled isDisabled={!isSelected}>
          <video controls>
            <source src={URL.createObjectURL(blob)} />
          </video>

          <UploadingMediaIcon moment={moment}></UploadingMediaIcon>
          <TopRightButtonWrapper
            blockClientId={blockClientId}
            showButton={isHovered}
            moment={moment}
          />
        </Disabled>
      </div>
    );
  }

  if (isInGallery) {
    return (
      <div
        {...blockProps}
        sx={{
          div: {
            my: 0,
          },
        }}
      >
        <FailedMedia icon="video" moment={moment} />
      </div>
    );
  }

  return <FailedMedia icon="video" moment={moment} />;
};
