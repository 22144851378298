export enum EVENT {
  accountCreationComplete = "account_creation_complete",
  accountCreationStart = "account_creation_start",
  attachmentAdd = "attachment_add",
  attachmentDownload = "attachment_download",
  attachmentDelete = "attachment_delete",
  attachmentUpload = "attachment_upload",
  attachmentUploadSuccess = "attachment_upload_success",
  attachmentUploadFailed = "attachment_upload_failed",
  dailyPromptUsed = "daily_prompt_used",
  presetPromptUsed = "preset_prompt_used",
  buttonTap = "button_tap",
  dowloadAppTopBannerClicked = "download_app_top_banner_clicked",
  encryptionKeyEntered = "encryption_key_entered",
  encryptionKeyError = "encryption_key_entered_error",
  entryCommentDeleted = "entry_comment_deleted",
  entryCommentAdded = "entry_comment_added",
  entryCommentEdited = "entry_comment_edited",
  entryCommentReactionAdded = "entry_comment_reaction_added",
  entryCommentReactionDeleted = "entry_comment_reaction_deleted",
  entryCreate = "entry_create",
  entryDelete = "entry_delete",
  entryEditFinish = "entry_edit_finish",
  entryUpload = "entry_upload",
  focusModeOn = "enter_focus_mode",
  journalCreate = "journal_create",
  journalDelete = "journal_delete",
  journalSelect = "journal_select",
  journalSyncOff = "journal_sync_disabled",
  journalSyncOn = "journal_sync_enabled",
  journalUpdate = "journal_update",
  prefilledEntryUsedForApp = "prefilled_entry_used_for_app",
  prefilledEntryUsedForWeb = "prefilled_entry_used_for_web",
  prefilledEntryViewed = "prefilled_entry_viewed",
  prefilledPromptViewed = "prefilled_prompt_viewed",
  screenView = "screen_view",
  stripeClickThrough = "stripe_clickthrough",
  stripeNoUpgrade = "stripe_returned_no_upgrade",
  stripeSubscriptionSuccess = "stripe_subscription_success",
  slashCommandUse = "slash_shortcut_use",
  supportFormSubmitted = "support_form_submitted",
  supportFormSubmittedFailed = "support_form_submitted_failed",
  supportFormConfigFetched = "support_form_config_fetched",
  supportFormConfigFetchedFailed = "support_form_config_fetched_failed",
  tagAdded = "tag_added_to_entry",
  tagRemoved = "tag_removed_from_entry",
  templateAdded = "template_added",
  templateDeleted = "template_deleted",
  templateApplied = "template_applied",
  upgradeModalDismissed = "upgrade_modal_dismissed",
  upgradeModalShown = "upgrade_modal_shown",
  userSignIn = "user_sign_in",
  userSignOut = "user_sign_out",
  entryReactionAdded = "entry_reaction_added",
  entryReactionDeleted = "entry_reaction_deleted",
  entryReactionEdited = "entry_reaction_edited",
  requestJoinSharedJournal = "request_join_shared_journal",
  searchFilterApplied = "filter_applied",
  searchFilterRemoved = "filter_removed",
  schemaError = "dexie_schema_error",
  entryMoveCreated = "entry_move_created",
  entryMoveStarted = "entry_move_started",
  entryMoveFailed = "entry_move_failed",
  entryMoveFinished = "entry_move_finished",
  // These are "moment copy" events (I guess we didn't unify on the naming very well)
  backendAttachmentFulfillmentGeneratingPlan = "backend_attachment_fulfillment_generating_plan",
  backendAttachmentFulfillmentGeneratingEncryptionDetails = "backend_attachment_fulfillment_generating_encryption_details",
  backendAttachmentFulfillmentRequest = "backend_attachment_fulfillment_request",
  backendAttachmentFulfillmentSuccessful = "backend_attachment_fulfillment_successful",
  backendAttachmentFulfillmentFailure = "backend_attachment_fulfillment_failure",
}
