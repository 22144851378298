import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import { SelectControl } from "@/components/Form/SelectControl";
import { SelectItemWrapper } from "@/components/Settings/SelectItemWrapper";
import { useColorMode } from "@/styles/DayOneColorModeProvider";
import { isElectronApp } from "@/utils/environment";
import { viewStates } from "@/view_state/ViewStates";

export const ColorMode: React.FC = observer(() => {
  const selectedTheme = viewStates.theme.selectedTheme;
  const { setTheme } = useColorMode();
  const { __ } = useI18n();

  const handleThemeChange = (value: string) => {
    if (!value) return;

    setTheme(value);

    if (isElectronApp() && window.electron?.setTheme) {
      window.electron.setTheme(value as "light" | "dark" | "system");
    }
  };

  return (
    <SelectItemWrapper sx={{ width: "100%", py: "10px" }}>
      <SelectControl
        label={__("Theme")}
        value={selectedTheme!}
        options={[
          { label: __("Light"), value: "light" },
          { label: __("Dark"), value: "dark" },
          { label: __("System"), value: "system" },
        ]}
        onChange={handleThemeChange}
      />
    </SelectItemWrapper>
  );
});
