import { PropsWithChildren } from "react";

type Props = {
  header: React.ReactNode;
  className?: string;
};

export const SettingsPanel: React.FC<PropsWithChildren<Props>> = ({
  header,
  children,
  className,
}) => {
  return (
    <div className={className}>
      <header
        sx={{
          borderBottom: "1px solid",
          borderBottomColor: "borderPrimary",
          fontWeight: 600,
          "& span": {
            py: 3,
            px: 4,
            height: "50px",
            display: "block",
          },
          "& button": {
            fontWeight: 600,
            fontSize: 2,
            height: "50px",
            ":hover": {
              opacity: "0.6",
            },
          },
        }}
      >
        {header}
      </header>
      <main
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          height: "100%",
          pb: 3,
        }}
      >
        <div sx={{ width: "100%", fontSize: "0.8rem", px: 3 }}>{children}</div>
      </main>
    </div>
  );
};
