import { isElectronWindows } from "@/utils/environment";

// Base colors that are shared between platforms
const baseColors = {
  lightHover: "rgba(0, 54, 102, 0.05)",
  lightActive: "rgba(0, 54, 102, 0.1)",
  lightDrag: "rgba(0, 100, 102, 0.06)",
  darkHover: "rgba(255, 255, 255, 0.06)",
  darkActive: "rgba(255, 255, 255, 0.12)",
  darkDrag: "rgba(255, 255, 255, 0.08)",
};

// Windows Electron-specific colors
const windowsColors = {
  light1: "#FFFFFF",
  light2: "#f3f3f3",
  light3: "#EBEBEB",
  dark1: "#131313",
  dark2: "#202020",
  dark3: "#272727",
  dark4: "#333333",
  dark5: "#383838",
  darkToolbar: "#1A1A1A",
  ...baseColors,
};

// Web-specific colors
const webColors = {
  light1: "#FFFFFF",
  light2: "#F3F5F7",
  light3: "#E8EBEE",
  dark1: "#111314",
  dark2: "#202324",
  dark3: "#252829",
  dark4: "#313435",
  dark5: "#36393A",
  darkToolbar: "#181A1B",
  ...baseColors,
};

// Consistent shadows across platforms
const surfaceShadows = {
  shadow1: "0px 1px 3px rgba(0, 0, 0, 0.1)",
  shadow2: "0px 3px 10px rgba(0, 0, 0, 0.2)",
  shadow3: "0px 3px 30px rgba(25, 30, 35, 0.2);",
};

const surfaceColors = isElectronWindows() ? windowsColors : webColors;

export { surfaceColors, surfaceShadows };
