import { RichTextJSON, RTJNode } from "types/rtj-format";

import { isEmbeddedMotionActivityNode } from "@/components/Editor/rtj2gb/rtj-type-checks";
import { EntryDBRow } from "@/data/db/migrations/entry";

export const EntryFeatureFlag = {
  MultipleAttachments: 0x0001,
  NonJpeg: 0x0002,
  UserLabel: 0x0004, // Deprecated
  Audio: 0x0008,
  RichTextJSON: 0x0010,
  Video: 0x0020,
  Sketch: 0x0040,
  PDF: 0x0080,
  HighlightedText: 0x0100,
  IsPinned: 0x0200,
  EntryType: 0x0800,
  ExtendedEmbedTypes: 0x1000,
  ExtendedEmbedTypesV2: 0x2000,
  UnderlinedText: 0x4000,
} as const;

export type EntryFeatureFlagType =
  (typeof EntryFeatureFlag)[keyof typeof EntryFeatureFlag];

export interface MomentFeatureChecks {
  nonJpeg: boolean;
  audio: boolean;
  video: boolean;
  pdf: boolean;
  sketch: boolean;
  multipleAttachments: boolean;
}

/**
 * This function calculates the feature flags for an entry based on the entry and other moment related checks.
 * We are using a Left Shift operator to calculate the decimal value of the feature flags
 * and then converting it to a hexadecimal value.
 *
 * See Fieldguide page for deatils on how we calculate the feature flags
 * https://fieldguide.automattic.com/day-one-engineering-docs/entry-feature-flags/
 *
 * 0x0001 multiple attachments
 * 0x0002 gif or png photos
 * 0x0004 user label (deprecated)
 * 0x0008 audio
 * 0x0010 rich text json
 * 0x0020 video
 * 0x0040 sketch
 * 0x0080 PDF
 * 0x0100 highlighted text
 * 0x0200 isPinned
 * 0x0800 entryType field
 * 0x1000 Extended Embed Types • Activity • Contact • Location • Podcast • Song • Step Count • Workout
 * 0x2000 Extended Embed Types v2 • Updated Activity • State of Mind • Generic Media
 * 0x4000 underlined text
 */
export const calculateFeatureFlags = (
  entry: EntryDBRow,
  momentFeatureChecks: MomentFeatureChecks,
  rtj?: RichTextJSON,
) => {
  let updatedFeatures = 0;

  // 0x0001 multiple attachments
  if (momentFeatureChecks.multipleAttachments) {
    updatedFeatures += EntryFeatureFlag.MultipleAttachments;
  }

  // 0x0002 gif or png photos
  if (momentFeatureChecks.nonJpeg) {
    updatedFeatures += EntryFeatureFlag.NonJpeg;
  }

  // 0x0004 user label (deprecated)
  if (entry.location?.userLabel) {
    updatedFeatures += EntryFeatureFlag.UserLabel;
  }

  // 0x0008 audio
  if (momentFeatureChecks.audio) {
    updatedFeatures += EntryFeatureFlag.Audio;
  }

  // 0x0010 rich text json
  updatedFeatures += EntryFeatureFlag.RichTextJSON;

  // 0x0020 video
  if (momentFeatureChecks.video) {
    updatedFeatures += EntryFeatureFlag.Video;
  }

  // 0x0040 drawing/sketch
  if (momentFeatureChecks.sketch) {
    updatedFeatures += EntryFeatureFlag.Sketch;
  }

  // 0x0080 PDF
  if (momentFeatureChecks.pdf) {
    updatedFeatures += EntryFeatureFlag.PDF;
  }

  // 0x0100 highlighted text
  if (entry.rich_text_json?.match(`\\"highlightedColor\\":`)) {
    updatedFeatures += EntryFeatureFlag.HighlightedText;
  }

  // 0x0200 isPinned
  if (entry.is_pinned) {
    updatedFeatures += EntryFeatureFlag.IsPinned;
  }

  // 0x0800 entryType field
  if (typeof entry.entry_type !== "undefined") {
    updatedFeatures += EntryFeatureFlag.EntryType;
  }

  // 0x1000 Extended Embed Types • Activity • Contact • Location • Podcast • Song • Step Count • Workout
  if (
    entry.rich_text_json?.match(
      `\\"type\\":\\"contact|location|podcast|song|motionActivity|workout\\"`,
    )
  ) {
    updatedFeatures += EntryFeatureFlag.ExtendedEmbedTypes;
  }

  // 0x2000 Extended Embed Types v2 • Updated Activity • State of Mind • Generic Media
  if (
    entry.rich_text_json?.match(`\\"type\\":\\"genericMedia|stateOfMind\\"`) ||
    (rtj && hasV2MotionActivityNodes(rtj.contents))
  ) {
    updatedFeatures += EntryFeatureFlag.ExtendedEmbedTypesV2;
  }

  // 0x4000 underlined text
  if (entry.rich_text_json?.match(`\\"underline\\":`)) {
    updatedFeatures += EntryFeatureFlag.UnderlinedText;
  }

  return updatedFeatures.toString(16);
};

// Precompute validFlags once
const validFlags = Object.values(EntryFeatureFlag).reduce((acc, flag) => {
  return acc | flag;
}, 0);

/**
 * Takes an hexadecimal string of EntryFeatureFlags and returns true if all the flags are allowed
 *
 * @param entryFlags - The hexadecimal string representing the feature flags
 * @returns true if all the flags are allowed, false otherwise
 */
export const validateEntryFeatureFlags = (entryFlags: string) => {
  const flagValue = parseInt(entryFlags, 16);
  // Check if all flags in entryFlags are valid
  return (flagValue & validFlags) === flagValue;
};

const hasV2MotionActivityNodes = (nodes: RTJNode[]) => {
  return nodes.some(
    (node) =>
      isEmbeddedMotionActivityNode(node) &&
      (node.embeddedObjects[0].movementType ||
        node.embeddedObjects[0].movementTypeName),
  );
};
