import { useCallback, useEffect, useState } from "react";

import { Sentry } from "@/Sentry";
import { useExternalScript } from "@/hooks/useExternalScript";

export const GOOGLE_IDENTITY_SERVICES_URL =
  "https://accounts.google.com/gsi/client";

// Defaults to the client ID from getting auth key from Google Drive
const CLIENT_ID = process.env.VITE_GOOGLE_OAUTH_CLIENT_ID || "";

interface Props {
  client_id?: string;
}

export const useGoogleLogin = (props?: Props) => {
  const client_id = props?.client_id || CLIENT_ID;
  const gsiState = useExternalScript(GOOGLE_IDENTITY_SERVICES_URL);
  const [token, setToken] = useState("");
  const [googleLibraryLoaded, setGoogleLibraryLoaded] = useState(false);
  const [authorized, setAuthorized] = useState(false);

  const handleCredentialResponse = useCallback(
    (response: google.accounts.id.CredentialResponse) => {
      try {
        setToken(JSON.stringify({ token: response.credential }));
      } catch (error) {
        Sentry.captureException(
          new Error(`Failed to handle Google credential response: ${error}`),
        );
      }
    },
    [],
  );

  const initGoogleLibrary = useCallback(() => {
    if (!window.google?.accounts?.id) {
      console.error("Google Identity Services library not loaded");
      return;
    }

    try {
      const config: google.accounts.id.IdConfiguration = {
        client_id,
        callback: handleCredentialResponse,
        use_fedcm_for_prompt: true,
        cancel_on_tap_outside: false,
        context: "signin",
      };

      window.google.accounts.id.initialize(config);
      setGoogleLibraryLoaded(true);
    } catch (error) {
      Sentry.captureException(
        new Error(`Failed to initialize Google Identity Services: ${error}`),
      );
    }
  }, [client_id, handleCredentialResponse]);

  const processOauthToken = useCallback(
    (
      scope: string,
      action: (token: google.accounts.oauth2.TokenResponse) => void,
      connectErrorMessage: string,
    ) => {
      if (!googleLibraryLoaded || !window.google?.accounts?.oauth2) {
        return;
      }

      try {
        window.google.accounts.oauth2
          .initTokenClient({
            client_id,
            scope: scope,
            callback: async (
              response: google.accounts.oauth2.TokenResponse,
            ) => {
              await action(response);
              setAuthorized(true);
            },
            error_callback: (
              response: google.accounts.oauth2.ClientConfigError,
            ) => {
              // Don't send error if user closes the pop up
              if (response.message !== "Popup window closed") {
                Sentry.captureException(
                  new Error(`${connectErrorMessage} - ${response.message}`),
                );
              }
            },
          })
          .requestAccessToken();
      } catch (error) {
        Sentry.captureException(
          new Error(`Failed to process OAuth token: ${error}`),
        );
      }
    },
    [googleLibraryLoaded, client_id],
  );

  const processOauthCode = useCallback(
    (
      scope: string,
      action: (token: string) => void,
      connectErrorMessage: string,
    ) => {
      if (!googleLibraryLoaded || !window.google?.accounts?.oauth2) {
        return;
      }

      window.google.accounts.oauth2
        .initCodeClient({
          client_id,
          scope: scope,
          callback: async (response: google.accounts.oauth2.CodeResponse) => {
            await action(response.code);
            setAuthorized(true);
          },
          error_callback: (
            response: google.accounts.oauth2.ClientConfigError,
          ) => {
            Sentry.captureException(
              new Error(`${connectErrorMessage} - ${response.message}`),
            );
          },
        })
        .requestCode();
    },
    [googleLibraryLoaded, client_id],
  );

  window.onGoogleLibraryLoad = () => {
    initGoogleLibrary();
  };

  useEffect(() => {
    switch (gsiState) {
      case "ready":
        initGoogleLibrary();
        break;
      case "loaded": {
        const googleGSILibraryIsLoaded =
          window.google && window.google.accounts && window.google.accounts.id;
        if (googleGSILibraryIsLoaded) {
          setGoogleLibraryLoaded(true);
        }
        break;
      }
      default:
        break;
    }
  }, [gsiState, initGoogleLibrary]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (window.google?.accounts?.id) {
        window.google.accounts.id.cancel();
      }
    };
  }, []);

  return {
    googleLibraryLoaded,
    token,
    processOauthToken,
    processOauthCode,
    authorized,
  };
};
