import { ThemeUICSSObject } from "@theme-ui/css";
import { useI18n } from "@wordpress/react-i18n";
import { useState } from "react";

import { D1Dropdown } from "@/components/D1Dropdown";
import { DraggableMediaButton } from "@/components/Editor/components/DraggableMediaButton";
import { MissingMediaInfo } from "@/components/Editor/components/FailedMedia";
import { MediaButton } from "@/components/SyncableImage/MediaButton";
import { MediaMenu } from "@/components/SyncableImage/MediaMenu";
import { ImageErrorStates } from "@/components/SyncableImage/utils";
import { MomentModel } from "@/data/models/MomentModel";

const BUTTON_SIZE = 24;
const BUTTON_GAP = 8;

export const topRightButtonStyles: ThemeUICSSObject = {
  cursor: "pointer",
  backgroundColor: "black",
  borderRadius: "xs",
  height: `${BUTTON_SIZE}px`,
  opacity: "0.5",
  color: "white",
};

export const TopRightButtonWrapper = ({
  blockClientId,
  moment,
  imageErrorState,
  showButton,
  rotateLeft,
}: {
  blockClientId: string;
  moment?: MomentModel;
  imageErrorState?: ImageErrorStates;
  showButton?: boolean;
  rotateLeft?: () => void;
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const { __ } = useI18n();

  const getRightMarginForDraggablePopover = () => {
    let blockCountNextToDraggable = 1; // media menu

    if (imageErrorState === "LOW_RES") {
      blockCountNextToDraggable += 1;
    }

    return (BUTTON_SIZE + BUTTON_GAP) * blockCountNextToDraggable;
  };

  return (
    <div
      sx={{
        position: "absolute",
        top: "16px",
        right: "16px",
      }}
    >
      {(showButton || isDragging) && (
        <DraggableMediaButton
          clientId={blockClientId}
          onDragStart={() => setIsDragging(true)}
          onDragEnd={() => setIsDragging(false)}
          rightMargin={getRightMarginForDraggablePopover()}
        />
      )}
      {showButton && moment && (
        <div
          sx={{ display: "flex", alignItems: "center", gap: `${BUTTON_GAP}px` }}
        >
          {imageErrorState === "LOW_RES" && (
            <D1Dropdown
              renderToggle={({ isOpen, onToggle }) => {
                if (showButton) {
                  return (
                    <MediaButton
                      icon="info-circle"
                      label={__("Missing Media Details")}
                      onClick={onToggle}
                      aria-expanded={isOpen}
                    />
                  );
                }
              }}
              popoverProps={{ placement: "bottom-end" }}
              renderContent={() => (
                <div
                  sx={{
                    fontSize: 0,
                    lineHeight: "body",
                    width: "340px",
                    px: 3,
                    pt: 2,
                    color: "textSecondary",
                  }}
                >
                  <strong
                    sx={{
                      mb: 2,
                      display: "block",
                    }}
                  >
                    Low resolution media.
                  </strong>
                  <MissingMediaInfo moment={moment} />
                </div>
              )}
            />
          )}
          <MediaMenu
            journalId={moment.journalId}
            entryId={moment.entryId}
            momentId={moment.id}
            clientId={blockClientId}
            rotateLeft={rotateLeft}
          />
        </div>
      )}
    </div>
  );
};
