import { useI18n } from "@wordpress/react-i18n";

import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { ItemGroup } from "@/components/ItemGroup";
import { EditLinkButton } from "@/components/Settings/EditLinkButton";
import { ToggleSetting } from "@/components/Settings/ToggleSetting";
import { ServerFlagDBRow } from "@/data/db/migrations/server_flags";
import { isFlagEnabled } from "@/data/utils/serverFlags";

type Props = {
  flag: ServerFlagDBRow;
  onToggle: (flagId: string, value: boolean) => void;
};

export const AIFeatures: React.FC<Props> = ({ flag, onToggle }) => {
  const { __ } = useI18n();
  const value = isFlagEnabled(flag);

  return (
    <>
      <ItemGroup addMargin={true}>
        <ToggleSetting
          label={flag.title}
          checked={value}
          onChange={() => {
            onToggle(flag.id, !value);
          }}
        />
      </ItemGroup>
      <p>
        {__(`By enabling AI features, you consent to submitting certain content to
        our AI partner. Data is temporarily decrypted only when using AI
        features.`)}
      </p>
      <p>
        {__(`Our AI partner does not store or train on your data. It is solely used
          to generate content within Day One.`)}
      </p>
      <p>
        {__(
          "You can disable AI features anytime to hide all AI-related interactions.",
        )}
        <EditLinkButton
          onClick={() => {
            analytics.tracks.recordEvent(EVENT.buttonTap, {
              button_identifier: "settings_support_clicked",
            });
            window.open(
              "https://dayoneapp.com/labs/ai-features",
              "_blank",
              "noreferrer,noopener",
            );
          }}
          label={__("Learn more.")}
          title={__("Learn more.")}
        />
      </p>
    </>
  );
};
