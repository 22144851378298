import { Tooltip } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";

import { d1Classes } from "@/D1Classes";
import { Icon } from "@/components/Icon";
import { MomentModel } from "@/data/models/MomentModel";
import { useSubscription } from "@/hooks/d1_hooks";

type UploadingMediaIconProps = {
  moment: MomentModel;
};

export const UploadingMediaIcon: React.FC<UploadingMediaIconProps> = ({
  moment,
}) => {
  const { __ } = useI18n();

  const momentSubscribed = useSubscription<MomentModel>(
    (cb) => {
      d1Classes.momentStore.subscribeToMomentById(
        cb,
        moment.journalId,
        moment.entryId,
        moment.id,
      );
    },
    [moment.entryId, moment.journalId, moment.id, moment.isUploading],
  );

  return (
    <>
      {momentSubscribed?.isUploading && (
        <Tooltip text={__("Uploading...")}>
          <div
            sx={{
              position: "absolute",
              color: "white",
              backgroundColor: "black",
              borderRadius: "xs",
              opacity: "0.5",
              top: 3,
              left: 3,
            }}
          >
            <Icon
              icon="arrow-up-circle"
              size={5}
              iconStyles={{
                display: "block",
                animation: "pulse 1.5s ease-in-out infinite",
                "@keyframes pulse": {
                  "0%": { opacity: 0.5 },
                  "50%": { opacity: 1.0 },
                  "100%": { opacity: 0.5 },
                },
              }}
            />
          </div>
        </Tooltip>
      )}
    </>
  );
};
