import { ThemeUICSSObject } from "@theme-ui/core";
import { Button } from "@wordpress/components";
import { sprintf } from "@wordpress/i18n";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useLocation } from "wouter";

import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { D1Dropdown } from "@/components/D1Dropdown";
import { PromptAnswered } from "@/components/DailyPrompts/PromptAnswered";
import { EntriesByPrompt } from "@/components/DailyPrompts/RecentPromptsModal";
import { getRTJContentFromPrompt } from "@/components/DailyPrompts/utils";
import { MenuButton } from "@/components/Editor/components/MenuButton";
import { getBaseURL } from "@/data/URLFunctions";
import { Prompt } from "@/data/repositories/PromptsAPI";
import { useCreateNewEntry } from "@/hooks/useCreateNewEntry";
import { primaryViewState } from "@/view_state/ViewStates";

type Props = {
  prompts: Prompt[];
  journalId: string;
  type: string;
  entriesByPromptId: EntriesByPrompt;
  tags?: string[];
  onAction?: () => void;
};

const buttonStyles: ThemeUICSSObject = {
  width: "100%",
  display: "block",
  height: "auto",
  fontSize: 1,
  lineHeight: 3,
  textAlign: "left",
  backgroundColor: "surface_light2_dark2",
  paddingX: 3,
  fontWeight: "body",
  borderRadius: 2,
  "&&:hover": {
    backgroundColor: "surfaceActive",
    color: "inherit",
  },
};

export const PromptList: React.FC<Props> = observer(
  ({ prompts, journalId, type, entriesByPromptId, tags = [], onAction }) => {
    const { __, _n } = useI18n();
    const { selectedEntryView, selectedJournalId } = primaryViewState;
    const baseURL = getBaseURL(selectedJournalId, selectedEntryView);
    const [, setLocation] = useLocation();
    const { createNewEntry } = useCreateNewEntry(
      `${type}-prompt`,
      `${type}Prompt`,
    );
    const createNewEntryFromPrompt = (prompt: Prompt) => {
      const content = getRTJContentFromPrompt(prompt.content);

      createNewEntry(journalId, {
        prefill: {
          richTextJson: content,
          promptId: prompt?.id,
          tags: tags,
        },
      });
      analytics.tracks.recordEvent(EVENT.dailyPromptUsed, {
        daily_prompt_id: prompt?.id,
      });
    };
    const showEntries = (promptId: string) => {
      const url = `${baseURL}/prompt/${promptId}`;
      setLocation(url);
    };

    return (
      <div
        sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}
      >
        {prompts?.map((prompt) => {
          const isAnswered = !!entriesByPromptId[prompt.id];
          return isAnswered ? (
            <D1Dropdown
              key={prompt.id}
              renderToggle={({ isOpen, onToggle }) => (
                <Button
                  className="menu"
                  onClick={onToggle}
                  label={__("Daily Prompt Options Menu")}
                  sx={buttonStyles}
                  aria-expanded={isOpen}
                >
                  <div>
                    <span>{prompt.content}</span>
                    <PromptAnswered />
                  </div>
                </Button>
              )}
              sx={{
                width: "100%",
                "& .components-popover__content > div": {
                  p: 0,
                },
                "& .components-popover__content": {
                  borderWidth: 1,
                  borderColor: "borderPrimary",
                  borderRadius: "sm",
                  boxShadow: "shadow2",
                  padding: 0,
                  marginTop: "-3",
                  outline: "none",
                },
              }}
              popoverProps={{ placement: "bottom-end" }}
              renderContent={() => (
                <div
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    color: "editorTextMuted",
                    backgroundColor: "surface_light1_dark4",
                    minWidth: "190px",
                  }}
                >
                  <div
                    sx={{
                      padding: 2.5,
                      "& button": { mt: 1 },
                      "&& button": { fontSize: 0 },
                    }}
                  >
                    <MenuButton
                      onClick={() => {
                        showEntries(prompt.id);
                        onAction?.();
                      }}
                    >
                      {sprintf(
                        _n(
                          "View %d Entry",
                          "View %d Entries",
                          entriesByPromptId[prompt.id].length,
                        ),
                        entriesByPromptId[prompt.id].length,
                      )}
                    </MenuButton>
                    <MenuButton
                      onClick={() => {
                        createNewEntryFromPrompt(prompt);
                        onAction?.();
                      }}
                    >
                      {__("Answer Again")}
                    </MenuButton>
                  </div>
                </div>
              )}
            />
          ) : (
            <Button
              key={prompt.id}
              label={__("Answer Prompt")}
              sx={buttonStyles}
              onClick={() => {
                createNewEntryFromPrompt(prompt);
                onAction?.();
              }}
            >
              <span> {prompt.content}</span>
            </Button>
          );
        })}
      </div>
    );
  },
);
