import { AnimatePresence, motion } from "framer-motion";
import { forwardRef } from "react";

interface Props {
  src: string;
  width?: number;
  height?: number;
  onClick?: () => void;
}

export const FullImage = forwardRef<HTMLImageElement, Props>((props, ref) => {
  const { src, width, height, onClick } = props;
  return (
    <AnimatePresence>
      <motion.img
        ref={ref}
        initial={{ filter: "blur(10px)" }}
        animate={{ filter: "blur(0px)" }}
        exit={{ opacity: 0 }}
        transition={{ ease: "easeOut", duration: 0.2 }}
        src={src}
        width={width}
        height={height}
        onClick={onClick}
      />
    </AnimatePresence>
  );
});
