import { RTJNode } from "@/../types/rtj-format";
import { removeZeroWidthNonBreakingSpaces } from "@/components/Editor/gb2rtj/formatting";
import { md5 } from "@/crypto/utils/md5";
import {
  Activity,
  EntryDBRow,
  GlobalEntryID,
  Weather,
} from "@/data/db/migrations/entry";
import { MomentDBRow } from "@/data/db/migrations/moment";
import { isDeepEqual } from "@/utils/is-equal";

export class EntryModel {
  richTextJSON: { contents: RTJNode[] };
  date: number;
  weather: Weather;
  changed = false;
  contentChanged = false;

  // These are the moments that were in the databse when the entry was loaded
  // They aren't live updated. They aren't used when saving the entry or creating
  // new moments. Please don't get confused! These are here for figuring out whether
  // we need to add new blocks to the end of the entry in case moments were added
  // to the entry but left out of the contents.
  private _readOnlyMomentsFromDBAtInitialization: MomentDBRow[] | undefined;

  constructor(
    private data: EntryDBRow,
    moments?: MomentDBRow[],
  ) {
    // know it seems weird to parse it twice but I want to make sure they aren't the same object
    this.richTextJSON = this.data.rich_text_json
      ? JSON.parse(this.data.rich_text_json)
      : { contents: [] };

    this.date = this.data.date;
    this.weather = this.data.weather;
    this._readOnlyMomentsFromDBAtInitialization = moments;
  }

  get readOnlyMomentsFromDBAtInitialization() {
    return this._readOnlyMomentsFromDBAtInitialization;
  }

  get body() {
    return this.data.body;
  }

  set body(body: string) {
    this.data.body = body;
    this.changed = true;
    this.contentChanged = true;
  }

  get id() {
    return this.data.id;
  }

  get editDate() {
    return this.data.edit_date;
  }

  set editDate(date: number) {
    this.data.edit_date = date;
  }

  get ownerUserId() {
    return this.data.owner_user_id;
  }

  get creatorUserId() {
    return this.data.creator_user_id;
  }

  get activity() {
    return this.data.activity;
  }

  get steps() {
    return this.data.steps;
  }

  get music() {
    return this.data.music;
  }

  get globalID(): GlobalEntryID {
    return {
      id: this.id,
      journal_id: this.journalId,
    };
  }

  get timeZone() {
    return this.data.timezone
      ? this.data.timezone
      : Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  get location() {
    return this.data.location;
  }

  get journalId() {
    return this.data.journal_id;
  }

  get editingTime() {
    return this.data.editing_time;
  }

  get revisionID() {
    return this.data.revision_id;
  }

  get clientMeta() {
    return JSON.parse(this.data.client_meta);
  }

  get entryContents() {
    return this.richTextJSON.contents;
  }

  get isDeleted() {
    return this.data.is_deleted;
  }

  get isStarred() {
    return !!this.data.is_starred;
  }

  get isPinned() {
    return !!this.data.is_pinned;
  }

  get isAllDay() {
    return !!this.data.is_all_day;
  }

  get promptID() {
    return this.data.promptID;
  }

  get reactions() {
    return this.data.reactions;
  }

  get unread_marker_id() {
    return this.data.unread_marker_id;
  }

  get isShared() {
    return this.data.is_shared;
  }

  get duration() {
    return this.data.duration;
  }

  get entryData() {
    return this.data;
  }

  public resetChangedState() {
    this.changed = false;
    this.contentChanged = false;
  }

  async updateDate(date: Date) {
    this.date = date.getTime();
    this.weather = null;
    this.changed = true;
  }

  // Tags have been updated we just need to infom the change on the entry
  async updatedTags() {
    this.changed = true;
  }

  async updateTemplateID(templateID: string) {
    this.changed = true;
    this.data.templateID = templateID;
  }

  async toggleFavorite() {
    this.changed = true;
    this.data.is_starred = this.data.is_starred ? 0 : 1;
  }

  async togglePinned() {
    this.changed = true;
    this.data.is_pinned = this.data.is_pinned ? 0 : 1;
  }

  async toggleAllDay() {
    this.changed = true;
    this.data.is_all_day = this.data.is_all_day ? 0 : 1;
  }

  async updateActivity(activity: Activity) {
    this.changed = true;
    this.data.activity = activity;
  }

  async updateContents(
    markdown: string,
    contents: RTJNode[],
    editDate: number,
    templateID?: string,
  ) {
    // Remove zero width non breaking spaces before we compare the contents
    const cleanContents = removeZeroWidthNonBreakingSpaces(contents);

    if (!isDeepEqual(this.richTextJSON.contents, cleanContents)) {
      this.changed = true;
      this.contentChanged = true;
      this.richTextJSON = { contents: cleanContents };
      this.data.body = markdown;
    }

    if (templateID && this.data.templateID != templateID) {
      this.changed = true;
      this.data.templateID = templateID;
    }

    if (this.contentChanged) {
      this.editDate = editDate;
      this.userEditDate = editDate;
    }
  }

  get textContent() {
    return this.richTextJSON.contents.reduce((acc, node) => {
      if ("text" in node) {
        acc += node.text;
      }
      return acc;
    }, "");
  }

  get isChanged() {
    return this.changed;
  }

  get isContentChanged() {
    return this.contentChanged;
  }

  get templateID() {
    return this.data.templateID;
  }

  public equals(other: EntryModel | null) {
    if (!other) {
      return false;
    }
    return this.hash == other.hash;
  }

  get userEditDate() {
    return this.data.user_edit_date;
  }

  set userEditDate(date: number) {
    this.data.user_edit_date = date;
  }

  get featureFlags() {
    return this.data.feature_flags;
  }

  // A string that changes whenever the entry has significant changes
  public get hash() {
    return md5(
      this.data.id + this.data.date + this.data.rich_text_json + this.data.body,
    );
  }

  public copy() {
    return new EntryModel({ ...this.data });
  }
}

export const globalEntryIDToString = (ids: GlobalEntryID) => {
  return `${ids.journal_id}:${ids.id}`;
};

export const globalEntryIDFromString = (str: string) => {
  const [journal_id, id] = str.split(":");
  if (!journal_id || !id) {
    throw new Error("Invalid entry IDs string: ${str}");
  }
  return { journal_id, id };
};

export const globalEntryIDsAreEqual = (
  a: GlobalEntryID | null | undefined,
  b: GlobalEntryID | null | undefined,
) => {
  return a?.id == b?.id && a?.journal_id == b?.journal_id;
};
