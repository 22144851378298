import { useI18n } from "@wordpress/react-i18n";

import { ItemGroup } from "@/components/ItemGroup/ItemGroup";
import { AppVersion } from "@/components/Settings/Advanced/AppVersion";
import { ClearGoogleDrive } from "@/components/Settings/Advanced/ClearGoogleDrive";
import { DebugLogging } from "@/components/Settings/Advanced/DebugLogging";
import { StorageDetails } from "@/components/Settings/Advanced/StorageDetails";
import { SyncDetails } from "@/components/Settings/Advanced/SyncDetails/SyncDetails";
import { UsageStatistics } from "@/components/Settings/Advanced/UsageStatistics";
import { SettingsPanel } from "@/components/Settings/SettingsPanel";

export const Advanced: React.FC = () => {
  const { __ } = useI18n();

  return (
    <SettingsPanel header={<span>{__("Advanced")}</span>}>
      <ItemGroup addMargin={true}>
        <UsageStatistics />
      </ItemGroup>
      <ItemGroup addMargin={true}>
        <DebugLogging />
      </ItemGroup>
      <SyncDetails />
      <StorageDetails />
      <ClearGoogleDrive />
      <AppVersion />
    </SettingsPanel>
  );
};
