import { Icon, IconFont } from "@/components/Icon";
import { topRightButtonStyles } from "@/components/SyncableImage/TopRightButton";

type Props = {
  onClick: () => void;
  icon: IconFont;
  className?: string;
  label: string;
};

export const MediaButton: React.FC<Props> = ({
  onClick,
  icon,
  className,
  label,
}) => {
  return (
    <div
      role="button"
      sx={topRightButtonStyles}
      onClick={onClick}
      className={className}
      aria-label={label}
    >
      <Icon icon={icon} />
    </div>
  );
};
